import { useMutation, useQuery } from "@tanstack/vue-query";
import { ReadSessionKey } from "@/query/keys";
import { LogoutProp, ReadCompanySessionResult } from "@/types/Session";
import { logout, readCompanySession } from "@/query/apis/Session";

export const useCompanySession = () =>
  useQuery<ReadCompanySessionResult>([ReadSessionKey], () => readCompanySession());

export const useLogoutMutation = () => {
  return useMutation((data: LogoutProp) => logout(data));
};
