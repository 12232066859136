import { reactive, UnwrapNestedRefs } from "vue";

export default class State<T extends object = any> {
  modal: UnwrapNestedRefs<{ [key: string]: boolean }>;
  state: UnwrapNestedRefs<T>;
  initialState: T;

  constructor(initialState?: T) {
    this.modal = reactive<{ [id: string]: boolean }>({});
    if (initialState) {
      this.initialState = initialState;
      this.state = reactive<T>(JSON.parse(JSON.stringify(initialState)));
    }
  }

  getState() {
    return this.state as T;
  }
  resetState() {
    Object.assign(this.state, JSON.parse(JSON.stringify(this.initialState)));
  }
  setObject(state: T) {
    Object.assign(this.state, JSON.parse(JSON.stringify(state)));
  }

  getModal(key: string) {
    return this.modal.value[key] ?? false;
  }
  openModal(key: string) {
    this.modal.value[key] = true;
  }
  closeModal(key: string) {
    this.modal.value[key] = false;
  }
}
